import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import ReactGA from 'react-ga';
import { MuiThemeProvider } from '@material-ui/core/styles';
import LoadingScreen from './components/LoadingScreen/LoadingScreen';
import theme from './theme/appTheme';
import Router from './Router';
import { ContextController } from './Context';

const trackingId = 'UA-111783029-1'; // Replace with your Google Analytics tracking ID
ReactGA.initialize(trackingId);
ReactGA.pageview(window.location.pathname + window.location.search);
// ReactGA.set({
//   userId: auth.currentUserId(),
//   // any data that is relevant to the user session
//   // that you would like to track with google analytics
// })

class App extends React.Component {
	state = {
		// loading: true,
		loaded: false,
	};

	componentDidMount() {
		ReactGA.pageview(window.location.pathname + window.location.search);

		window.addEventListener('load', () => {
			// this.setState({ loading: false });
			setTimeout(() => {
				this.setState({ loaded: true });
			}, 360);
		});
	}

	render() {
		const { loaded } = this.state;
		const main = loaded ? <Router /> : <LoadingScreen />;

		return (
			<MuiThemeProvider theme={theme}>
				<ContextController>
					<BrowserRouter>{main}</BrowserRouter>
				</ContextController>
			</MuiThemeProvider>
		);
	}
}

export default App;
