import React from 'react';
import PropTypes from 'prop-types';

const AppContext = React.createContext();
export const useAppContext = () => React.useContext(AppContext);

const ContextController = ({ children }) => {
  const intialState = {
    isAuthorized: false,
  };
  const [state, setState] = React.useState(intialState);

  return <AppContext.Provider value={[state, setState]}>{children}</AppContext.Provider>;
};

ContextController.propTypes = {
  children: PropTypes.element.isRequired,
};
export { ContextController, AppContext };
