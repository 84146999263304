import React from 'react';
import { NavLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
// import Button from '@material-ui/core/Button';
// import Tooltip from '@material-ui/core/Tooltip';
// import HelpIcon from '@material-ui/icons/Help';
import { DisconnectButton } from '../../DisconnectButton';
// import { useAppContext } from '../../../Context';

const useStyles = makeStyles(theme => ({
  root: {
    flexDirection: 'column',
    flexGrow: 1,
  },
  title: {
    flexGrow: 1,
    // [theme.breakpoints.up('sm')]: {
    //   paddingLeft: theme.spacing(6),
    // },
  },
  tabs: {
    flexGrow: 1,
    // [theme.breakpoints.up('sm')]: {
    //   paddingLeft: theme.spacing(6),
    // },
  },
  profilePopover: {
    width: '300px',
    padding: theme.spacing(1),
    '&:focus': {
      outline: 'none',
    },
  },
  menuItem: {
    textAlign: 'right',
  },
  responsive: {
    width: '150px',
  },
  menuItemIcon: {
    marginLeft: theme.spacing(1),
  },
}));

const Header = () => {
  const classes = useStyles();
  // const [context] = useAppContext();

  return (
    <div className={classes.root}>
      <AppBar color="primary" position="static" className={classes.appBar}>
        <Toolbar>
          <Typography className={classes.title}>
            <NavLink
              to="/"
              style={{
                textDecoration: 'none',
                color: 'white',
                outline: 'none',
              }}
            >
              Mainnet.com
            </NavLink>
          </Typography>
          <DisconnectButton />
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default Header;
