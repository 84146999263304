/* eslint-disable no-unused-vars */
import React from 'react';
import { makeStyles, Drawer } from '@material-ui/core';

const drawerWidth = 0;
const useStyles = makeStyles(theme => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
}));

const Navigator = props => {
  const classes = useStyles();
  return (
    <Drawer
      className={classes.drawer}
      variant="permanent"
      classes={{
        paper: classes.drawerPaper,
      }}
    />
  );
};

export default Navigator;
