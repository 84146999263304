import React from 'react';
// import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

// const useStyles = makeStyles(theme => ({
//   root: {
//     width: '100%',
//   },
// }));

const DisconnectButton = () => {
  // const classes = useStyles();
  // let web3 = useRef(null);

  const disconnectWallet = () => {
  }

  return (
    <Button 
      color="inherit" 
      style={{ display: 'none', outline: 'none' }} 
      onClick={disconnectWallet}
    >Disconnect Wallet</Button>
  );
};

export default DisconnectButton;
