import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Hidden from '@material-ui/core/Hidden';
import { Header, Navigator } from './index';

const useStyles = makeStyles(theme => ({
  Layout: {
    minHeight: '100vh',
  },
  Nav: {
    width: '60px',
  },
  // MainBody: {
  //   [theme.breakpoints.up('sm')]: {
  //     marginLeft: '60px',
  //   },
  // },
  LayoutContent: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
}));

const drawerWidth = 0;

const Layout = props => {
  const classes = useStyles();
  const { children } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    const isMobileOpen = !mobileOpen;
    setMobileOpen(isMobileOpen);
  };
  return (
    <div className={classes.Layout}>
      <CssBaseline />
      <nav className={classes.Nav}>
        <Hidden xsDown implementation="js">
          <Navigator
            PaperProps={{ style: { width: drawerWidth } }}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
          />
        </Hidden>
        <Hidden xsUp implementation="css">
          <Navigator PaperProps={{ style: { width: drawerWidth } }} />
        </Hidden>
      </nav>
      <div className={classes.LayoutContent}>
        <Header onDrawerToggle={handleDrawerToggle} />
        <div className={classes.MainBody}>{children}</div>
      </div>
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.element.isRequired,
};

Layout.defaultProps = {
  // children: null,
};

export default Layout;
