import { createMuiTheme } from '@material-ui/core';

import palette from './palette';
// import typography from './typography';
import overrides from './overrides';

const muiTheme = createMuiTheme({
  palette,
  // typography,
  overrides,
  zIndex: {
    appBar: 1200,
    drawer: 1100,
  },
});

const appTheme = {
  ...muiTheme,
  props: {
    MuiTab: {
      disableRipple: true,
    },
  },
  mixins: {
    ...muiTheme.mixins,
    toolbar: {
      minHeight: 48,
    },
  },
};

export default appTheme;
