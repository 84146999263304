import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { asyncComponent } from './hoc';
import { Layout as LayoutWrapper } from './components/Layout';
// import { useAppContext } from './Context';

// User containers
const asyncHome = asyncComponent(() => import('./views/Home'));

// Authenticated Routes
const Routes = () => {
	return (
		<LayoutWrapper>
			<Switch>
				<Route path="/" component={asyncHome} />
			</Switch>
		</LayoutWrapper>
	);
};

// Check if the user is authenticated (check context API)
const Router = () => {
	// const [context] = useAppContext();
	// account = context.account;
	// const isAuthorized = context.isAuthorized;
	const routes = Routes;

	return (
		<div className="theme-light">
			<main>
				<Switch>
					<Route
						path="/chainlist"
						component={() => {
							window.location.href = 'https://chainlist.wtf';
							return null;
						}}
					/>
					<Route
						path="/tokenlist"
						component={() => {
							window.location.href = 'https://tokenlists.org/';
							return null;
						}}
					/>
					<Route
						path="/explorer"
						component={() => {
							window.location.href = 'https://blockscan.com/';
							return null;
						}}
					/>
					<Route
						path="/daplist"
						component={() => {
							window.location.href = 'https://dappradar.com/rankings/1';
							return null;
						}}
					/>

					<Route component={routes} />
				</Switch>
			</main>
		</div>
	);
};

export default Router;
